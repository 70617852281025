import request from '@/utils/request'

// 凭证列表
export function getList(data) {
  return request({
    url: '/cashAdmin/proof/list',
    method: 'post',
    data,
  })
}

// 获取编码
export function getCode(data) {
  return request({
    url: '/cashAdmin/proof/code',
    method: 'post',
    data,
  })
}

// 会计科目列表全部
export function financialList(data) {
  return request({
    url: '/cashAdmin/subject/list-all',
    method: 'post',
    data,
  })
}

// 凭证详情
export function getDetail(data) {
  return request({
    url: '/cashAdmin/proof/info',
    method: 'post',
    data,
  })
}

// 凭证作废
export function deleteRow(data) {
  return request({
    url: '/cashAdmin/proof/out',
    method: 'post',
    data,
  })
}

// 编辑凭证
export function editFinancial(data) {
  return request({
    url: '/cashAdmin/proof/update',
    method: 'post',
    data,
  })
}

// 新增凭证
export function addFinancial(data) {
  return request({
    url: '/cashAdmin/proof/add',
    method: 'post',
    data,
  })
}

// 凭证审核
export function reviewFinancial(data) {
  return request({
    url: '/cashAdmin/proof/check',
    method: 'post',
    data,
  })
}

/**
 * 利润报表  profit
 */

// 利润报表
export function getProfitList(data) {
  return request({
    url: '/cashAdmin/subject-detail/rate',
    method: 'post',
    data,
  })
}

// 月份下拉
export function monthList(data) {
  return request({
    url: '/cashAdmin/subject-detail/month',
    method: 'post',
    data,
  })
}

// 年份下拉
export function yearList(data) {
  return request({
    url: '/cashAdmin/subject-detail/year',
    method: 'post',
    data,
  })
}

/**
 * 费用审批
 */

// 费用科目列表
export function subjectList(data) {
  return request({
    url: '/cashAdmin/funds/type-list',
    method: 'post',
    data,
  })
}

// 费用审批单列表
export function constList(data) {
  return request({
    url: '/cashAdmin/funds/funds-list',
    method: 'post',
    data,
  })
}

// 费用审批单详情
export function constDetail(data) {
  return request({
    url: '/cashAdmin/funds/detail',
    method: 'post',
    data,
  })
}

// 作废 费用审批单.
export function deleteCost(data) {
  return request({
    url: '/cashAdmin/funds/scrap',
    method: 'post',
    data,
  })
}

// 后台账户下拉
export function accountList(data) {
  return request({
    url: '/baseAdmin/common/account',
    method: 'post',
    data,
  })
}

// 添加、修改 费用审批单
export function accountAdd(data) {
  return request({
    url: '/cashAdmin/funds/save',
    method: 'post',
    data,
  })
}

// 审核费用审批单
export function accountReview(data) {
  return request({
    url: '/cashAdmin/funds/check',
    method: 'post',
    data,
  })
}

/**
 * @科目余额查询
 */
//  科目余额表

export function balanceQuery(data) {
  return request({
    url: '/cashAdmin/subject-detail/balance',
    method: 'post',
    data,
  })
}

// 月份下拉
export function kemuMonthList(data) {
  return request({
    url: '/cashAdmin/subject-detail/month',
    method: 'post',
    data,
  })
}

// 年份下拉
export function kemuYearList(data) {
  return request({
    url: '/cashAdmin/subject-detail/year',
    method: 'post',
    data,
  })
}
/**
 * @科目明细查询
 */

//  资产负债表
// 左边表格
export function leftTableData(data) {
  return request({
    url: '/cashAdmin/subject/list',
    method: 'post',
    data,
  })
}

// 明细分类账
// 右边表格数据
export function rightTableData(data) {
  return request({
    url: '/cashAdmin/subject-detail/list',
    method: 'post',
    data,
  })
}
