<template>
  <el-dialog
    :modal="false"
    title="记账凭证"
    :visible.sync="showDialog"
    width="1000px"
    top="5vh"
    :close-on-click-modal="false"
    destroy-on-close
    append-to-body
  >
    <div class="orderTest-container">
      <accounting
        ref="accounting"
        :shwo-con="false"
        @current-row="changeItem"
      ></accounting>
    </div>
  </el-dialog>
</template>
<script>
  import Accounting from '@/views/setting/otherManagement/accounting'
  export default {
    name: 'FinancialDialog',
    components: { Accounting },
    data() {
      return {
        showDialog: false,
      }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {
      changeItem(val) {
        console.log(val)
        ;(this.showDialog = false), this.$emit('change-all', val)
      },
    },
  }
</script>
<style lang="scss" scoped></style>
