var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: _vm.title,
        visible: _vm.showDialog,
        width: "1280px",
        top: "5vh",
        "close-on-click-modal": false,
        "destroy-on-close": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _vm.type != 4
        ? _c("div", { staticClass: "status-tip" }, [
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.status == 1,
                  expression: "form.status == 1",
                },
              ],
              attrs: { src: require("@/assets/dsh.png"), alt: "" },
            }),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.status == 2,
                  expression: "form.status == 2",
                },
              ],
              attrs: { src: require("@/assets/ysh.png"), alt: "" },
            }),
            _c("img", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.form.status == 3,
                  expression: "form.status == 3",
                },
              ],
              attrs: { src: require("@/assets/yzf.png"), alt: "" },
            }),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "orderTest-container",
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                inline: "",
                "label-position": "right",
                "label-width": "90px",
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "凭证字:", prop: "code_name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "140px" },
                    attrs: { value: _vm.form.code_name, disabled: "" },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "号:", prop: "code_no" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "140px" },
                    attrs: { disabled: "" },
                    model: {
                      value: _vm.form.code_no,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "code_no", $$v)
                      },
                      expression: "form.code_no",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "日期:", prop: "create_at" } },
                [
                  _c("el-date-picker", {
                    staticStyle: { width: "180px", "margin-right": "35px" },
                    attrs: {
                      type: "date",
                      placeholder: "选择日期",
                      format: "yyyy 年 MM 月 dd 日",
                      "value-format": "yyyy-MM-dd",
                      disabled: _vm.type != 4 ? true : false,
                    },
                    model: {
                      value: _vm.form.create_at,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "create_at", $$v)
                      },
                      expression: "form.create_at",
                    },
                  }),
                ],
                1
              ),
              _c("el-form-item", { attrs: { label: "", prop: "" } }, [
                _vm._v(" " + _vm._s(_vm.qihao) + " "),
              ]),
              _c("div"),
              _c(
                "el-form-item",
                { attrs: { label: "制单人:", prop: "add_name" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "140px" },
                    attrs: { value: _vm.form.add_name, disabled: "" },
                  }),
                ],
                1
              ),
              _vm.type == 1
                ? _c(
                    "el-form-item",
                    { attrs: { label: "审核人:", prop: "check_name" } },
                    [
                      _c("el-input", {
                        staticStyle: { width: "140px" },
                        attrs: { disabled: "" },
                        model: {
                          value: _vm.form.check_name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "check_name", $$v)
                          },
                          expression: "form.check_name",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              attrs: {
                stripe: "",
                data: _vm.form.child_data,
                "show-summary": "",
                "summary-method": _vm.handleSum,
              },
              on: { "cell-click": _vm.cellClick },
            },
            [
              _c("el-table-column", {
                attrs: { align: "center", type: "index", width: "50" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "note",
                  label: "摘要",
                  width: "110px",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("el-input", {
                          attrs: {
                            placeholder: "摘要",
                            disabled: _vm.type == 1,
                          },
                          model: {
                            value: row.note,
                            callback: function ($$v) {
                              _vm.$set(row, "note", $$v)
                            },
                            expression: "row.note",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "subject_name",
                  label: "会计科目",
                  width: "130px",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        _c("type-input", {
                          ref: "typeInput",
                          attrs: {
                            kw: row.subject_name,
                            disab: _vm.type == 1,
                            search: _vm.type != 1,
                          },
                          on: {
                            "update:kw": function ($event) {
                              return _vm.$set(row, "subject_name", $event)
                            },
                            "select-all": function ($event) {
                              return _vm.financialId($event, row)
                            },
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c(
                "el-table-column",
                { attrs: { label: "借方金额", align: "center" } },
                _vm._l(_vm.jfCheckList, function (jkitem, jkindex) {
                  return _c("el-table-column", {
                    key: jkindex,
                    attrs: {
                      prop: jkitem.prop,
                      label: jkitem.label,
                      width: jkitem.width,
                      align: "center",
                    },
                  })
                }),
                1
              ),
              _c(
                "el-table-column",
                { attrs: { label: "贷方金额", align: "center" } },
                _vm._l(_vm.dkCheckList, function (dkitem, dkindex) {
                  return _c("el-table-column", {
                    key: dkindex,
                    attrs: {
                      prop: dkitem.prop,
                      label: dkitem.label,
                      width: dkitem.width,
                      align: "center",
                    },
                  })
                }),
                1
              ),
              _vm.type != 1
                ? _c("el-table-column", {
                    attrs: {
                      prop: "",
                      align: "center",
                      label: "操作",
                      "min-width": "65px",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (ref) {
                            var $index = ref.$index
                            var row = ref.row
                            return [
                              _c(
                                "el-button",
                                {
                                  attrs: { type: "text" },
                                  nativeOn: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.handleDeleteRow($index, row)
                                    },
                                  },
                                },
                                [_vm._v(" 删除 ")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      2211543734
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            title: _vm.title2,
            visible: _vm.showDialog2,
            width: "400px",
            top: "15vh",
            "close-on-click-modal": false,
            "destroy-on-close": "",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog2 = $event
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "orderTest-container",
              staticStyle: { padding: "10px 70px" },
            },
            [
              _c("el-input", {
                attrs: { min: 0, type: "number" },
                model: {
                  value: _vm.money,
                  callback: function ($$v) {
                    _vm.money = $$v
                  },
                  expression: "money",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-top": "5px", "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSubNum } },
                [_vm._v("确定")]
              ),
              _c("el-button", { on: { click: _vm.close2 } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          staticStyle: { "margin-top": "5px", "text-align": "right" },
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _vm.type == 2
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
                [_vm._v(" 保存 ")]
              )
            : _vm._e(),
          _vm.type == 4
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleAdd } },
                [_vm._v(" 添加 ")]
              )
            : _vm._e(),
          _vm.type == 3
            ? _c(
                "el-button",
                { attrs: { type: "warning" }, on: { click: _vm.handleReview } },
                [_vm._v(" 审核 ")]
              )
            : _vm._e(),
          _c("el-button", [_vm._v("打印")]),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }