<template>
  <div class="wrapper">
    <el-autocomplete
      v-model="keyword"
      class="inline-input"
      value-key="name"
      :fetch-suggestions="querySearch"
      placeholder="科目类型"
      :disabled="disab"
      @select="handleSelect"
      @input="handleInput"
    >
      <i
        v-if="search"
        slot="suffix"
        class="el-input__icon el-icon-search"
        @click="handleIconClick"
      ></i>
      <!-- <template slot-scope="{ item }">
        <div class="wrap" style="display: flex">
          <div class="name" style="width: 50px; margin-right: 120px">
            {{ item.goods_name }}
          </div>
          <span class="addr">{{ item.specs }}</span>
        </div>
      </template> -->
    </el-autocomplete>
    <financial-dialog
      ref="financialDialog"
      @change-all="changeAll"
    ></financial-dialog>
  </div>
</template>
<script>
  import { financialList } from '@/api/financial'
  import FinancialDialog from './financialDialog'
  export default {
    name: 'TypeInput',
    components: { FinancialDialog },
    props: {
      popperClass: {
        type: String,
        default: () => 'selectDC',
      },
      kw: {
        type: String,
        default: () => '',
      },
      disab: {
        type: Boolean,
        default: () => false,
      },
      search: {
        type: Boolean,
        default: () => true,
      },
    },
    data() {
      return {
        keyword: '',
        res: [],
      }
    },
    computed: {},
    watch: {
      kw(val) {
        if (val) {
          console.log(this.kw)
          this.keyword = val
        } else {
          console.log(this.kw)
          this.keyword = ''
        }
      },
    },
    created() {
      console.log(this.kw)
      this.keyword = JSON.parse(JSON.stringify(this.kw))
    },
    mounted() {},
    methods: {
      async fetchData(ky = '') {
        let { data, code, msg } = await financialList({
          name: this.keyword,
        })
        console.log('123')
        console.log(data)
        if (code == 200) {
          this.res = data
          return data
        } else {
          this.$message.error(msg)
        }
      },
      handleIconClick() {
        console.log(111)
        this.$refs.financialDialog.showDialog = true
      },
      querySearch(queryString, cb) {
        if (queryString == '') {
          cb([])
        } else {
          this.fetchData(queryString).then((val) => {
            cb(val)
          })
        }
      },
      handleSelect(val) {
        console.log(val)
        this.$emit('select-id', val.id)
        this.$emit('select-all', val)
      },
      handleInput(val) {
        console.log(val)
        if (val == '') {
          this.$emit('select-id', '')
          this.$emit('select-all', {})
        } else {
          this.$emit('select-id', -1)
          this.$emit('select-all', { id: -1 })
        }
      },
      changeAll(val) {
        this.keyword = val.name
        this.$emit('select-id', val.id)
        this.$emit('select-all', val)
      },
      resetForm() {
        this.keyword = ''
      },
    },
  }
</script>
<style lang="scss" scoped></style>
