<template>
  <el-dialog
    :modal="false"
    :title="title"
    :visible.sync="showDialog"
    width="1280px"
    top="5vh"
    :close-on-click-modal="false"
    destroy-on-close
  >
    <div v-if="type != 4" class="status-tip">
      <img v-show="form.status == 1" src="@/assets/dsh.png" alt="" />
      <img v-show="form.status == 2" src="@/assets/ysh.png" alt="" />
      <img v-show="form.status == 3" src="@/assets/yzf.png" alt="" />
    </div>
    <div v-loading="loading" class="orderTest-container">
      <el-form
        ref="form"
        :model="form"
        inline
        label-position="right"
        label-width="90px"
      >
        <el-form-item label="凭证字:" prop="code_name">
          <el-input
            :value="form.code_name"
            disabled
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item label="号:" prop="code_no">
          <el-input
            v-model="form.code_no"
            disabled
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item label="日期:" prop="create_at">
          <el-date-picker
            v-model="form.create_at"
            type="date"
            placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd"
            style="width: 180px; margin-right: 35px"
            :disabled="type != 4 ? true : false"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="" prop="">
          {{ qihao }}
        </el-form-item>
        <div></div>
        <el-form-item label="制单人:" prop="add_name">
          <el-input
            :value="form.add_name"
            disabled
            style="width: 140px"
          ></el-input>
        </el-form-item>
        <el-form-item v-if="type == 1" label="审核人:" prop="check_name">
          <el-input
            v-model="form.check_name"
            disabled
            style="width: 140px"
          ></el-input>
        </el-form-item>
      </el-form>
      <el-table
        v-loading="loading"
        stripe
        :data="form.child_data"
        show-summary
        :summary-method="handleSum"
        @cell-click="cellClick"
      >
        <!-- 序号 -->
        <el-table-column
          align="center"
          type="index"
          width="50"
        ></el-table-column>
        <el-table-column prop="note" label="摘要" width="110px" align="center">
          <template #default="{ row }">
            <el-input
              v-model="row.note"
              placeholder="摘要"
              :disabled="type == 1"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
          prop="subject_name"
          label="会计科目"
          width="130px"
          align="center"
        >
          <template #default="{ row }">
            <type-input
              ref="typeInput"
              :kw.sync="row.subject_name"
              :disab="type == 1"
              :search="type != 1"
              @select-all="financialId($event, row)"
            ></type-input>
          </template>
        </el-table-column>
        <el-table-column label="借方金额" align="center">
          <el-table-column
            v-for="(jkitem, jkindex) in jfCheckList"
            :key="jkindex"
            :prop="jkitem.prop"
            :label="jkitem.label"
            :width="jkitem.width"
            align="center"
          ></el-table-column>
        </el-table-column>
        <el-table-column label="贷方金额" align="center">
          <el-table-column
            v-for="(dkitem, dkindex) in dkCheckList"
            :key="dkindex"
            :prop="dkitem.prop"
            :label="dkitem.label"
            :width="dkitem.width"
            align="center"
          ></el-table-column>
        </el-table-column>
        <el-table-column
          v-if="type != 1"
          prop=""
          align="center"
          label="操作"
          min-width="65px"
        >
          <template #default="{ $index, row }">
            <el-button
              type="text"
              @click.native.prevent="handleDeleteRow($index, row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :modal="false"
      :title="title2"
      :visible.sync="showDialog2"
      width="400px"
      top="15vh"
      :close-on-click-modal="false"
      destroy-on-close
      append-to-body
    >
      <div class="orderTest-container" style="padding: 10px 70px">
        <el-input v-model="money" :min="0" type="number"></el-input>
      </div>
      <div
        slot="footer"
        class="dialog-footer"
        style="margin-top: 5px; text-align: right"
      >
        <el-button type="primary" @click="handleSubNum">确定</el-button>
        <el-button @click="close2">取 消</el-button>
      </div>
    </el-dialog>
    <div
      slot="footer"
      class="dialog-footer"
      style="margin-top: 5px; text-align: right"
    >
      <el-button v-if="type == 2" type="primary" @click="handleSave">
        保存
        <!-- 提交 -->
      </el-button>
      <el-button v-if="type == 4" type="primary" @click="handleAdd">
        添加
        <!-- 提交 -->
      </el-button>
      <el-button v-if="type == 3" type="warning" @click="handleReview">
        审核
      </el-button>

      <el-button>打印</el-button>
      <el-button @click="close">取 消</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import _ from 'lodash'
  import TypeInput from './typeInput'
  import {
    getCode,
    getDetail,
    editFinancial,
    addFinancial,
    reviewFinancial,
  } from '@/api/financial'
  import { dateFormat } from '@/utils/Time.js'
  export default {
    name: 'Edit',
    components: { TypeInput },
    data() {
      return {
        type: 1, // 1:查看 2:编辑 3:审核 4:添加
        title: '标题',
        title2: '请输入金额',
        id: '',
        currentRow: {},
        rowtype: '',
        showDialog: false,
        showDialog2: false,
        loading: false,
        qihao: '',
        data: {},
        money: '',
        jfCheckList: [
          {
            prop: 'jf.yy',
            width: '40px',
            label: '亿',
          },
          {
            prop: 'jf.qw',
            width: '40px',
            label: '千',
          },
          {
            prop: 'jf.bw',
            width: '40px',
            label: '百',
          },
          {
            prop: 'jf.sw',
            width: '40px',
            label: '十',
          },
          {
            prop: 'jf.w',
            width: '40px',
            label: '万',
          },
          {
            prop: 'jf.q',
            width: '40px',
            label: '千',
          },
          {
            prop: 'jf.b',
            width: '40px',
            label: '百',
          },
          {
            prop: 'jf.s',
            width: '40px',
            label: '十',
          },
          {
            prop: 'jf.y',
            width: '40px',
            label: '元',
          },
          {
            prop: 'jf.j',
            width: '40px',
            label: '角',
          },
          {
            prop: 'jf.f',
            width: '40px',
            label: '分',
          },
        ],
        dkCheckList: [
          {
            prop: 'df.yy',
            width: '40px',
            label: '亿',
          },
          {
            prop: 'df.qw',
            width: '40px',
            label: '千',
          },
          {
            prop: 'df.bw',
            width: '40px',
            label: '百',
          },
          {
            prop: 'df.sw',
            width: '40px',
            label: '十',
          },
          {
            prop: 'df.w',
            width: '40px',
            label: '万',
          },
          {
            prop: 'df.q',
            width: '40px',
            label: '千',
          },
          {
            prop: 'df.b',
            width: '40px',
            label: '百',
          },
          {
            prop: 'df.s',
            width: '40px',
            label: '十',
          },
          {
            prop: 'df.y',
            width: '40px',
            label: '元',
          },
          {
            prop: 'df.j',
            width: '40px',
            label: '角',
          },
          {
            prop: 'df.f',
            width: '40px',
            label: '分',
          },
        ],
        form: {
          add_name: '',
          create_at: '',
          code_no: '',
          check_name: '',
          status: '',
          code_name: '记',
          child_data: [
            // {
            //   money: 0,
            //   subject_name: '',
            //   note: '',
            //   subject_id: '',
            //   subject_code: '',
            //   df: {
            //     f: '',
            //     j: '',
            //     y: '',
            //     s: '',
            //     b: '',
            //     q: '',
            //     w: '',
            //     sw: '',
            //     bw: '',
            //     qw: '',
            //     yy: '',
            //   },
            //   jf: {
            //     f: '',
            //     j: '',
            //     y: '',
            //     s: '',
            //     b: '',
            //     q: '',
            //     w: '',
            //     sw: '',
            //     bw: '',
            //     qw: '',
            //     yy: '',
            //   },
            // },
          ],
        },
        moneySum1: '',
        moneySum2: '',
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (val) {
          if (this.type != 4) {
            this.fetchData()
          }
        } else {
          this.resetForm()
        }
      },
      'form.create_at'(val) {
        if (val) {
          let v = val.split('-')
          console.log(v)
          this.qihao = v[0] + '年' + '第' + v[1] + '期'
        }
      },
      showDialog2(val) {
        if (!val) {
          this.money = ''
        }
      },
    },
    created() {
      this.initTime()
      this.initName()
      this.handleGetCode()
    },
    mounted() {
      this.addRow()
    },
    methods: {
      initTime() {
        let time = dateFormat(new Date())
        this.form.create_at = time
      },
      initName() {
        this.form.add_name = this.$store.getters['user/username']
      },
      async handleGetCode() {
        let { data, msg } = await getCode({ date: this.form.create_at })
        this.form.code_no = data.code
      },
      async fetchData() {
        this.loading = true
        let { data, msg } = await getDetail({ id: this.id })
        console.log(data)
        data.child_data.forEach((item) => {
          if (item.type == 1) {
            // 贷方金额
            item.df = this.initMoney(item.money)
            item.jf = this.obj()
          } else if (item.type == 2) {
            item.jf = this.initMoney(item.money)
            item.df = this.obj()
          }
        })
        this.form = data
        if (this.type != 1) {
          this.addRow()
        }
        this.loading = false
      },
      initMoney(money) {
        money = Number(money).toFixed(2) + ''
        let l = money.length
        if (money[l - 3] != '.') {
          alert('分解金额出错')
          return false
        }
        if (Number(money) < 1) {
          return {
            f: money[l - 1] || '',
            j: money[l - 2] || '',
            y: '',
            s: money[l - 5] || '',
            b: money[l - 6] || '',
            q: money[l - 7] || '',
            w: money[l - 8] || '',
            sw: money[l - 9] || '',
            bw: money[l - 10] || '',
            qw: money[l - 11] || '',
            yy: money[l - 12] || '',
          }
        } else {
          return {
            f: money[l - 1] || '',
            j: money[l - 2] || '',
            y: money[l - 4] || '',
            s: money[l - 5] || '',
            b: money[l - 6] || '',
            q: money[l - 7] || '',
            w: money[l - 8] || '',
            sw: money[l - 9] || '',
            bw: money[l - 10] || '',
            qw: money[l - 11] || '',
            yy: money[l - 12] || '',
          }
        }
      },
      rowClick(row, e, c) {
        console.log(row, e, c)
        console.log(row.jk.prop)
      },
      cellClick(row, column, cell) {
        if (column.property == undefined || column.property.indexOf('f') != 1) {
          return false
        } else {
          console.log('df')
          console.log(row, column, cell)
          this.currentRow = row
          if (this.type != 1) {
            if (column.property.split('.')[0] == 'df') {
              // 贷方
              if (row.subject_id != '' && row.subject_id != -1) {
                console.log('贷方')
                this.rowtype = 1
                this.handleDf(row)
              }
            } else if (column.property.split('.')[0] == 'jf') {
              if (row.subject_id != '' && row.subject_id != -1) {
                console.log('借方')
                this.rowtype = 2
                this.handleJk(row)
              }
            } else {
              return false
            }
          }
        }
      },
      handleDf(row) {
        this.title2 = '请输入贷方金额'
        console.log(row)
        this.currentRow = row
        this.showDialog2 = true
      },
      handleJk(row) {
        this.title2 = '请输入借方金额'
        console.log(row)
        this.currentRow = row
        this.showDialog2 = true
      },
      handleSubNum() {
        console.log('确定')
        this.clearNum()
        // if (this.money == '' || this.money == 0) {
        //   console.log('11')
        //   this.money = 0
        // }
        console.log('money', this.money)
        if (this.money != '' || this.money == 0) {
          console.log('开始')
          if (Number(this.money).toFixed(2).length > 12) {
            this.$message.error('超出最大数额')
            return false
          }
          // 确定金额
          console.log('确定金额')
          this.currentRow.money = this.money
          if (this.rowtype == 2) {
            this.currentRow.jf = this.initMoney(this.money)
            this.currentRow.type = 2
          } else if (this.rowtype == 1) {
            this.currentRow.df = this.initMoney(this.money)
            this.currentRow.type = 1
          }
          this.close2()
        } else if (this.money == '' || this.money == 0) {
          if (this.rowtype == 2) {
            this.currentRow.money = 0
            this.currentRow.jf = this.obj()
          } else if (this.rowtype == 1) {
            this.currentRow.money = 0

            this.currentRow.df = this.obj()
          }
          this.close2()
        }
      },
      clearNum() {
        this.currentRow.df = this.obj()
        this.currentRow.jf = this.obj()
      },
      obj() {
        return {
          f: '',
          j: '',
          y: '',
          s: '',
          b: '',
          q: '',
          w: '',
          sw: '',
          bw: '',
          qw: '',
          yy: '',
        }
      },
      close() {
        this.showDialog = false
      },
      close2() {
        this.showDialog2 = false
      },

      addRow() {
        this.form.child_data.push({
          money: '',
          type: 0,
          subject_name: '',
          note: '',
          subject_id: '',
          subject_code: '',
          df: this.obj(),
          jf: this.obj(),
        })
      },
      resetForm() {
        this.form.create_at = ''
        this.form.check_name = ''
        this.form.child_data = [
          {
            subject_name: '',
            note: '',
            subject_id: '',
            subject_code: '',
            df: this.obj(),
            jf: this.obj(),
          },
        ]
      },
      financialId(val, row) {
        console.log(val, row)
        row.subject_name = val.name
        row.subject_id = val.id
        row.note = val.name
        if (
          this.form.child_data[this.form.child_data.length - 1].subject_id != ''
        ) {
          this.addRow()
        }
      },
      handleDeleteRow(index, row) {
        console.log(index, row)
        if (this.form.child_data.length == 1) {
          this.$message.error('不能删除最后一行')
        } else if (row.subject_id == '') {
          this.$message.error('不能删除此行')
        } else {
          this.form.child_data.splice(index, 1)
        }
      },
      handleSum({ columns, data }) {
        console.log('合计计算==========')
        console.log(data)
        let b = []
        let sum1 = 0
        let sum2 = 0
        data.forEach((item2, idx) => {
          if (item2.type == 1 && item2.money) {
            sum1 += Number(item2.money)
          } else if (item2.type == 2 && item2.money) {
            sum2 += Number(item2.money)
          }
        })
        console.log(sum1, sum2)
        this.moneySum1 = sum1
        this.moneySum2 = sum2
        let fsumStr1 = sum1
          .toFixed(2)
          .replace(/\./, '')
          .split('')
          .reverse()
          .join('')
        let fsumStr2 = sum2
          .toFixed(2)
          .replace(/\./, '')
          .split('')
          .reverse()
          .join('')
        console.log(fsumStr2)
        columns.forEach((item, index) => {
          if (index == 0) {
            b[index] = '合计'
          } else if (index > 2 && index < 25) {
            for (let i = 0; i < fsumStr1.length; i++) {
              b[24 - i] = fsumStr1[i]
            }
            for (let i = 0; i < fsumStr2.length; i++) {
              b[13 - i] = fsumStr2[i]
            }
          } else {
            b[index] = ''
          }
        })
        return b
      },
      handleSave() {
        // 借贷金额不相等，请检查金额填写是否错误
        if (this.moneySum1 !== this.moneySum2) {
          this.$message.error('借贷金额不相等，请检查金额填写是否错误')
        } else {
          if (this.type == 2) {
            // 编辑
            this.save()
          } else if (this.type == 4) {
            // 添加
            // this.save(true)
            alert('添加')
          }
        }
      },
      handleReview() {
        this.save(true)
      },
      handleAdd() {
        let child_data = []
        this.form.child_data.forEach((item) => {
          if (item.subject_id !== '' && this.subject_id !== -1) {
            child_data.push(item)
          }
        })
        console.log('添加')

        addFinancial({
          create_at: this.form.create_at,
          child_data: JSON.stringify(child_data),
        }).then((res) => {
          this.$message.success(res.msg)
          this.$emit('refresh')
          this.close()
        })
      },
      save(isReview = false) {
        let child_data = []
        this.form.child_data.forEach((item) => {
          if (item.subject_id !== '' && this.subject_id !== -1) {
            child_data.push(item)
          }
        })
        console.log(child_data)
        editFinancial({
          id: this.id,
          child_data: JSON.stringify(child_data),
        }).then((res) => {
          if (isReview) {
            // 审核
            console.log('审核')
            reviewFinancial({ id: this.id }).then((ress) => {
              this.$message.success(ress.msg)
              this.$emit('refresh')
              this.close()
            })
          } else {
            console.log('编辑')
            this.$message.success(res.msg)
            this.close()
          }
          this.$emit('refresh')
        })
      },
    },
  }
</script>
<style lang="scss"></style>
